import React from 'react';
import { Text, Center, VStack, chakra } from '@chakra-ui/react';
import { SpotOnLogoHorizontal } from '../assets/images/SpotOnLogoHorizontal.ui';
import { useIsClient } from '@olo-web/utils/common/hooks/useIsClient';

export default function Home() {
  const isClient = useIsClient();

  const goBack = () => {
    if (isClient) {
      window?.history?.back();
    }
  };

  return (
    <Center h="100%">
      <VStack px={2}>
        <Text textStyle="h2" textAlign="center">
          Whoops! You've arrived here by mistake.
        </Text>
        <Text textStyle="p" textAlign="center">
          Please{' '}
          <chakra.span as="button" onClick={goBack} textDecor="underline" color="primary.350">
            go back
          </chakra.span>{' '}
          to the previous page.
        </Text>
        <SpotOnLogoHorizontal color="primary.350" maxW="150px" />
      </VStack>
    </Center>
  );
}
